<template>
  <v-chip style="background:none;">
    <v-icon color="info" left v-if="reason === 'New'">
      fiber_manual_record
    </v-icon>

    <v-icon color="success" left v-if="reason === 'Renewal'">
      cached
    </v-icon>

    <v-icon color="danger" left v-if="reason === 'Cancel'">
      clear
    </v-icon>

    <v-icon color="orange" left v-if="reason === 'Policy Change'">
      compare_arrows
    </v-icon>

    <v-icon color="blue" left v-if="reason === 'Installment'">
      account_balance
    </v-icon>

    <v-icon color="warning" left v-if="reason === 'Other'">
      donut_large
    </v-icon>

    {{reason}}
  </v-chip>
</template>

<script>

export default {
  props:['reason'],
  data(){
    return{
    }
  }
}
</script>

<style scoped>

</style>
