<template>
  <div class="d-flex">
    <div class="flex-grow-1">
      <money-input-with-previous-value
        v-model="record.agent_pay_raw"
        :previousValue="originalAmount"
        :disabled="!canEdit"
        v-if="showEditValueFields"
        @input="calculateRecordPercent(record)"
      />
    </div>
    <div class="flex-shrink-1" v-if="record.errors && record.errors.length">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="red--text mt-5 ml-3" v-bind="attrs" v-on="on">
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </template>
        <div v-if="record.errors.length">
          <div v-for="error in record.errors" class="my-2">{{ error }}</div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import MoneyInputWithPreviousValue from '@/pages/commissions/commission-statements/wizard/steps/producer/MoneyInputWithPreviousValue'
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiProgressCheck,
  mdiProgressClose,
  mdiProgressDownload,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import RecordAmountsMixin from '../RecordAmountsMixin'

export default {
  components: { MoneyInputWithPreviousValue },
  props: ['item', 'showEditValueFields'],
  mixins: [RecordAmountsMixin],
  data() {
    return {
      record: {
        original: {},
      },
      icons: {
        mdiAlertCircleOutline,
        mdiProgressDownload,
        mdiProgressCheck,
        mdiProgressClose,
        mdiCheckCircleOutline,
      },
    }
  },
  methods: {
    refreshRecord() {
      this.record = this.$_.cloneDeep(this.item)
    },
    calculateRecordPercent(record) {
      let isValid = this.validateRecord(record, 'amount')

      this.$debug.info('is amount valid ' + isValid)
      if (isValid) {
        if (record.agent_pay_raw === 0) {
          record.agent_pay_rate_raw = 0
        } else {
          record.agent_pay_rate_raw = ((record.agent_pay_raw / record.agency_commissions_raw) * 100).toFixed(2)
        }

        this.$debug.info('calculated record percent')
        this.updateRecordAmount(record)
      }
    },
  },
  mounted() {
    this.refreshRecord()
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.refreshRecord()
      },
    },
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
      isFinalized: 'commissions/monthlyReview/getFinalized',
    }),
    canEdit() {
      return this.isFinalized === false
    },
    originalAmount() {
      if (this.item.user_split_overwritten === false) {
        return null
      }

      if (this.commissionSetup.type === 'single') {
        if (this.item.type === 'NBS') {
          let percent = this.commissionSetup.new_percentage

          return (this.record.agency_commissions_raw * (percent / 100)).toFixed(2)
        }

        if (this.item.type === 'RWL') {
          let percent = this.commissionSetup.renewal_percentage

          return (this.record.agency_commissions_raw * (percent / 100)).toFixed(2)
        }
      }

      if (this.commissionSetup.type === 'multiple') {
        return this.item.original.value
      }
    },
  },
}
</script>

<style scoped></style>
