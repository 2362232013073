<template>
  <div>
    <div v-if="canEdit && ['NBS', 'RWL'].includes(item.type) && showPlanSelector(item)">
        <span v-if="isFinalized">{{ item.plan.attributes.name }}</span>
        <commission-plan-selector
          :key="item.id"
          :item="item"
          :showApproveFieldOutlines="showApproveFieldOutlines"
          v-else
        />
    </div>
    <finalized-plan-display :item="item" v-if="!canEdit" />
  </div>
</template>

<script>
import CommissionPlanSelector from '@/pages/commissions/commission-statements/wizard/steps/producer/CommissionPlanSelector'
import CommissionSinglePlanSelector from '@/pages/commissions/commission-statements/wizard/steps/producer/CommissionSinglePlanSelector'
import FinalizedPlanDisplay from '@/pages/commissions/commission-statements/wizard/steps/producer/FinalizedPlanDisplay'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { CommissionPlanSelector, CommissionSinglePlanSelector, FinalizedPlanDisplay },
  props: [
    'item',
    'showApproveFieldOutlines'
  ],
  methods: {
    ...mapActions({
      refreshCommissionStatement: 'commissions/producerStatement/refreshStatement',
    }),
    showPlanSelector(item) {
      if (item.ivans_status === 'pending') {
        //  return false;
      }

      return true
    },
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
      isFinalized: 'commissions/monthlyReview/getFinalized',
    }),
    canEdit() {
      return this.isFinalized === false
    },
  },
}
</script>

<style scoped></style>
