<template>
  <div class="text-right">
    <v-btn color="primary" @click.prevent="approve" :disabled="processing.approve || safeToApprove === false">
      Approve + Next
      <v-icon size="20" right>arrow_forward</v-icon>
    </v-btn>
    <br />
    <small class="ba-danger--text" v-if="safeToApprove === false">
      Incomplete commissions below
    </small>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as EVENTS from '@/config/globals/trackingEvents'
import _ from 'lodash'

export default {
  props: [],
  data() {
    return {
      processing: {
        approve: false,
      },
    }
  },
  methods: {
    approve() {
      if (this.isApproved) {
        this.$emit('approved')
        return
      }

      if (this.processing.approve || this.safeToApprove === false) {
        return
      }

      this.processing.approve = true

      this.approveStatement().then(() => {
        this.processing.approve = false
        this.$emit('approved')
        this.$tracking.event(EVENTS.APPROVED_PRODUCER_COMMISSION_STATEMENT, {
          user: this.activeUser,
          date: this.activeDate,
        })
      })
    },
    ...mapActions({
      refreshProducerList: 'commissions/producers/refreshProducers',
      refreshStatement: 'commissions/producerStatement/refreshStatement',
      setStatement: 'commissions/producerStatement/setStatement',
      approveStatement: 'commissions/producerStatement/approveStatement',
    }),
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
      producers: 'commissions/producers/getProducers',
      activeDate: 'commissions/producerStatement/getActiveDate',
      activeUser: 'commissions/producerStatement/getActiveUser',
      commissions: 'commissions/producerStatement/getCommissions',
      isApproved: 'commissions/producerStatement/getApproved',
    }),
    safeToApprove() {
      let allCommissionsHavePlan = true

      _.forEach(this.commissions, commission => {
        if (commission.attributes.user_split_overwritten) {
          return
        }

        if (['NBS', 'RWL'].includes(commission.attributes.reason.code) === false) {
          return
        }

        if (this.commissionSetup.type === 'single') {
          if (commission.attributes.user_split_amount === null) {
            allCommissionsHavePlan = false
            return
          }
        } else {
          if (_.has(commission, 'related.plan.data.id') === false) {
            allCommissionsHavePlan = false

            return
          }
        }
      })

      if (allCommissionsHavePlan) {
        return true
      } else {
        return false
      }
    },
    hasMoreUnapprovedUsers() {
      return (
        _.filter(this.producers, user => {
          return user.id !== this.filterUser && user.approved === false
        }).length > 0
      )
    },
    unapprovedUsers() {
      return _.filter(this.producers, user => {
        return user.approved === false
      })
    },
  },
}
</script>

<style></style>
