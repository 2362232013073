import _ from 'lodash'
import { mapActions } from 'vuex'
import * as yup from 'yup'

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      setCommissionPay: 'commissions/producerStatement/setCommissionPay',
      refreshStatementTotals: 'commissions/producerStatement/refreshStatementTotals',
    }),
    saveRecordAmount: _.debounce(function(record) {
      this.$api.commissions
        .updateAmount({
          commission: record.id,
          user_split_amount: record.agent_pay_raw,
        })
        .then(({ data }) => {
          this.$debug.info('Saved updated amount')
          this.refreshStatementTotals()
        })
    }, 800),
    updateRecordAmount(record) {
      let isValid = this.validateRecord(record)

      if (!isValid) {
        return
      }

      this.$debug.info('Sending request to update record amount')

      /**
       * First we will update locally
       */
      this.setCommissionPay({
        commissionId: record.id,
        pay: record.agent_pay_raw,
      })

      this.$debug.info('Updated record locally')

      /**
       * Now we will send to api
       */
      this.saveRecordAmount(record)
    },
    validateRecord(record, part) {
      let patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/
      this.$debug.info('validating record')
      this.$debug.info(record)

      let schema = null

      if (part === 'amount') {
        schema = yup.object().shape({
          agent_pay_raw: yup
            .number()
            .typeError('Commission must be a number')
            .required('Commission is required'),
        })
      } else if (part === 'percent') {
        schema = yup.object().shape({
          agent_pay_rate_raw: yup
            .number()
            .typeError('Rate must be a number')
            .required('Rate is required')
            .max(100, 'Rate max is 100%')
            .min(-100, 'Rate min is -100%'),
        })
      } else {
        schema = yup.object().shape({
          agent_pay_raw: yup
            .number()
            .typeError('Commission must be a number')
            .required('Commission is required'),
          agent_pay_rate_raw: yup
            .number()
            .typeError('Rate must be a number')
            .required('Rate is required')
            .max(100, 'Rate max is 100%')
            .min(-100, 'Rate min is -100%'),
        })
      }

      try {
        let validated = schema.validateSync(record)

        this.$debug.info('validated record')
        this.$debug.info(validated)
        record.errors = []
      } catch (e) {
        this.$debug.info('record failed validation')
        this.$debug.info(e)
        this.$debug.info(e.errors)
        record.errors = e.errors
        return false
      }

      return true
    },
  },
  mounted() {},
}
