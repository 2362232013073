<template>
  <v-data-table
    :headers="headers"
    :items="localTableData"
    :loading="loading"
    show-expand
    hide-default-footer
    disable-pagination
    id="ProducerCommissionsTable"
  >
    <template v-slot:item.effective_date="{ item }">
      <commission-effective-column :item="item" />
    </template>

    <template v-slot:item.type="{ item }">
      <commission-type-column :item="item" />
    </template>

    <template v-slot:item.lob="{ item }">
      {{ item.lob }}
    </template>

    <template v-slot:item.insured="{ item }">
      <commission-insured-column :item="item" />
    </template>

    <template v-slot:item.carrier="{ item }">
      <commission-carrier-column :item="item" />
    </template>

    <template v-slot:item.ivans_status="{ item }">
      <commission-status-column :item="item" />
    </template>

    <template v-slot:item.agency_commissions="{ item }">
      <commission-agency-commission-column :item="item" />
    </template>

    <template v-slot:item.plan="{ item }">
      <commission-plan-column :item="item" :showApproveFieldOutlines="showApproveFieldOutlines" />
    </template>

    <template v-slot:item.agent_pay_rate="{ item }">
      <commission-pay-rate-column :item="item" :showEditValueFields="showEditValueFields(item)" />
    </template>

    <template v-slot:item.agent_pay="{ item }">
      <commission-agent-pay-column :item="item" :showEditValueFields="showEditValueFields(item)" />
    </template>


    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="extendedRowContainer d-flex">
          <div class="flex-grow-1">
            <table>
              <tbody>
              <tr>
                <td class="expandedTitle">POLICY NUMBER</td>
                <td class="expandedValue">{{ item.policy }}</td>
              </tr>
              <tr>
                <td class="expandedTitle">LOB</td>
                <td class="expandedValue">{{ item.lob }}</td>
              </tr>
              <tr>
                <td class="expandedTitle">PREMIUM</td>
                <td class="expandedValue">{{ item.premium }}</td>
              </tr>
              <tr>
                <td class="expandedTitle">TERM</td>
                <td class="expandedValue">{{ item.terms }} Months</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-grow-1">
            <table>
              <tbody>
              <tr>
                <td class="expandedTitle">BASE AMOUNT</td>
                <td class="expandedValue">{{ item.final_amount }}</td>
              </tr>
              <tr v-if="hasAggregator">
                <td class="expandedTitle">AGGREGATOR COMMISSION</td>
                <td class="expandedValue">{{item.aggregator_cut}}</td>
              </tr>
              <tr v-if="hasAggregator">
                <td class="expandedTitle">AGENCY PAY</td>
                <td class="expandedValue">{{item.agency_commissions}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import AssignmentSelector from '@/pages/commissions/commission-statements/wizard/steps/producer/AssignmentSelector'
import CommissionPlanSelector from '@/pages/commissions/commission-statements/wizard/steps/producer/CommissionPlanSelector'
import CommissionSinglePlanSelector from '@/pages/commissions/commission-statements/wizard/steps/producer/CommissionSinglePlanSelector'
import ReasonChip from '@/pages/commissions/commission-statements/wizard/steps/producer/ReasonChip'
import { mapGetters } from 'vuex'

import CommissionAgencyCommissionColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionAgencyCommissionColumn'
import CommissionAgentPayColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionAgentPayColumn'
import CommissionCarrierColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionCarrierColumn'
import CommissionEffectiveColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionEffectiveColumn'
import CommissionInsuredColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionInsuredColumn'
import CommissionPayRateColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionPayRateColumn'
import CommissionPlanColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionPlanColumn'
import CommissionStatusColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionStatusColumn'
import CommissionTypeColumn from '@/pages/commissions/commission-statements/wizard/steps/producer/columns/CommissionTypeColumn'
import FinalizedPlanDisplay from '@/pages/commissions/commission-statements/wizard/steps/producer/FinalizedPlanDisplay'
import MoneyInputWithPreviousValue from '@/pages/commissions/commission-statements/wizard/steps/producer/MoneyInputWithPreviousValue'
import PercentInputWithPreviousValue from '@/pages/commissions/commission-statements/wizard/steps/producer/PercentInputWithPreviousValue'
import ReasonEditSelect from '@/pages/commissions/components/ReasonEditSelect'
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiProgressCheck,
  mdiProgressClose,
  mdiProgressDownload,
} from '@mdi/js'

export default {
  components: {
    CommissionCarrierColumn,
    CommissionInsuredColumn,
    CommissionEffectiveColumn,
    CommissionAgentPayColumn,
    CommissionAgencyCommissionColumn,
    CommissionPayRateColumn,
    CommissionTypeColumn,
    CommissionStatusColumn,
    CommissionPlanColumn,
    PercentInputWithPreviousValue,
    MoneyInputWithPreviousValue,
    ReasonEditSelect,
    FinalizedPlanDisplay,
    CommissionSinglePlanSelector,
    AssignmentSelector,
    CommissionPlanSelector,
    ReasonChip,
  },
  props: ['loading', 'showApproveFieldOutlines'],
  data() {
    return {
      loadFeatureFlags: {},
      localTableData: [],
      icons: {
        mdiAlertCircleOutline,
        mdiProgressDownload,
        mdiProgressCheck,
        mdiProgressClose,
        mdiCheckCircleOutline,
      },
      validations: [],
    }
  },
  methods: {
    showPlanSelector(item) {
      if (item.ivans_status === 'pending') {
        //  return false;
      }

      return true
    },
    showEditValueFields(item) {
      /**
       * If it doesn't have a type, don't allow edit. wait till they select a type like NBS, etc
       */
      if (!item.type || !item.type.length) {
        return false
      }

      return true
    },
  },
  mounted() {
    this.localTableData = this.$_.cloneDeep(this.tableData)
  },
  watch: {
    tableData: {
      deep: true,
      handler() {
        this.localTableData = this.$_.cloneDeep(this.tableData)
      },
    },
  },
  computed: {
    ...mapGetters({
      tableData: 'commissions/producerStatement/getCommissionsTable',
      commissionSetup: 'commissions/getCommissionSetup',
      currentUser: 'getCurrentUser',
    }),
    hasAggregator() {
      return this.currentUser?.related?.agency?.data?.attributes?.has_aggregator
    },
    headers() {
      let ivans = []

      if (this.currentUser?.related?.agency?.data?.attributes?.ivans_supported) {
        ivans = [
          {
            text: 'Ivans Status',
            align: 'start',
            sortable: false,
            value: 'ivans_status',
            class: 'columnStatus',
            cellClass: 'columnStatus',
          },
        ]
      }

      let output = [
        {
          text: 'Effective',
          align: 'start',
          sortable: false,
          value: 'effective_date',
          class: 'columnEffectiveDate',
          cellClass: 'columnEffectiveDate',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'type',
          class: 'columnType',
          cellClass: 'columnType',
        },
        {
          text: 'LOB',
          align: 'start',
          sortable: false,
          value: 'lob',
          class: 'columnLob',
          cellClass: 'columnLob',
        },
        {
          text: 'Insured',
          align: 'start',
          sortable: false,
          value: 'insured',
          class: 'columnInsured',
          cellClass: 'columnInsured',
        },
        {
          text: 'Carrier',
          align: 'start',
          sortable: false,
          value: 'carrier',
          class: 'columnCarrier',
          cellClass: 'columnCarrier',
        },
        ...ivans,
        {
          text: 'Agency Pay',
          align: 'start',
          sortable: false,
          value: 'agency_commissions',
          class: 'columnAgencyCommission',
          cellClass: 'columnAgencyCommission',
        },
        {
          text: 'Commission Plan',
          align: 'start',
          sortable: false,
          value: 'plan',
          class: 'columnPlan',
          cellClass: 'columnPlan',
        },
        {
          text: 'Rate',
          align: 'start',
          sortable: false,
          value: 'agent_pay_rate',
          class: 'columnProducerRate',
          cellClass: 'columnProducerRate',
        },
        {
          text: 'Producer Pay',
          align: 'start',
          sortable: false,
          value: 'agent_pay',
          class: 'columnProducerCommission',
          cellClass: 'columnProducerCommission',
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'columnExpand',
        },
      ]

      return output
    },
  },
}
</script>

<style lang="scss">
#ProducerCommissionsTable {
  padding: 0;

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(94, 86, 105, 0.87);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: rgba(94, 86, 105, 0.87);
  }

  td {
    padding: 16px 16px 16px 32px;
    border: none;
    background: white;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  th {
    vertical-align: top !important;
    padding: 16px 24px 16px 32px;
    background: #fff !important;
    border: none;

    /* Light/Components/Table Header */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 200% */

    text-transform: uppercase;

    /* Light/Text/Primary */

    color: #3a3541;
  }

  .columnProducerRate {
    min-width: 20px;
    max-width: 140px;
  }

  .columnType {
    min-width: 20px;
    max-width: 100px;
  }

  .columnInsured {
    min-width: 120px;
  }

  .columnPlan {
    min-width: 120px;
    max-width: 140px;
  }

  .columnProducerCommission {
    min-width: 20px;
    max-width: 130px;
  }

  .columnAgencyCommission {
    min-width: 120px;
    max-width: 120px;
  }

  .extendedRowContainer {
    background: #f1f1f2;
    width: 100%;
    table {
      background: none;
      border: none;
      width: 100%;

      .expandedValue {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3a3541;

        border: none;
        background: none !important;
        width: 50%;

        padding-top: 8px;
        padding-bottom: 8px;
      }

      .expandedTitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #3a3541;

        border: none;
        background: none !important;
        width: 50%;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .columnExpand {
    width: 40px;
    padding-left: 15px;
  }
}
</style>
