<template>
  <div>
    <v-select
      :value="value"
      :items="options"
      item-text="display"
      item-value="value"
      label="Select A Plan"
      :error="showError"
      single-line
      @change="valueChanged"
      style="width: 200px"
      ref="selector"
      dense
      hide-details
    >
      <template v-slot:item="{ item }">
        <div>
          <div>
            {{ item.display }}
          </div>
          <div v-if="item.rate.length" style="color: #7c7c7c">
            <small>{{ item.rate }}</small>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['item', 'showApproveFieldOutlines'],
  data() {
    return {
      value: '',
      showCustomLabel: false,
    }
  },
  methods: {
    valueChanged(newValue) {
      this.$debug.info('CommissionSinglePlanSelector.valueChanged')
      this.$debug.info(newValue)

      if (newValue === 'custom') {
        return
      }

      if (newValue === 'no-plan') {
        this.updateCommissionPlan({
          commissionId: this.item.id,
          planId: 'no-plan',
        })

        return
      }

      if (newValue === '') {
        this.$api.commissions
          .updatePlan({
            commission: this.item.id,
            plan: null,
          })
          .then(({ data }) => {
            this.setCommission(data)
            this.refreshStatementTotals()
          })
      }

      //else we just change it to default and set the values to the default
      if (newValue === 'default') {
        this.$api.commissions
          .updatePlan({
            commission: this.item.id,
            plan: 'default',
          })
          .then(({ data }) => {
            this.$emit('setToDefault', data)
            this.refreshStatementTotals()
          })
      }
    },
    initializeValue() {
      this.value = ''

      if (this.item.user_split_overwritten && parseFloat(this.item.agent_pay_raw) === 0) {
        this.value = 'no-plan'
      } else if (this.item.isNegative || (this.item.type !== 'NBS' && this.item.type !== 'RWL')) {
        this.value = 'custom'
      } else if (this.item.user_split_overwritten) {
        this.value = 'custom'
      } else if (
        this.item.type === 'NBS' &&
        this.item.plan === null &&
        parseFloat(this.item.agent_pay_rate_raw) === parseFloat(this.commissionSetup.new_percentage)
      ) {
        this.value = 'default'
      } else if (
        this.item.type === 'RWL' &&
        this.item.plan === null &&
        parseFloat(this.item.agent_pay_rate_raw) === parseFloat(this.commissionSetup.renewal_percentage)
      ) {
        this.value = 'default'
      }

      if (this.value) {
        this.$refs.selector.internalValue = this.value
      }
    },
    ...mapActions({
      setCommission: 'commissions/producerStatement/setCommission',
      refreshStatementTotals: 'commissions/producerStatement/refreshStatementTotals',
      updateCommissionPlan: 'commissions/producerStatement/updateCommissionPlan',
    }),
  },
  watch: {
    options(options) {
      this.$_.forEach(options, option => {
        if (option.value === 'custom') {
          if (this.item.user_split_overwritten && parseFloat(this.item.agent_pay_raw) !== 0) {
            this.value = 'custom'
            this.$refs.selector.internalValue = 'custom'
          }

          return false
        }

        if (option.value === 'no-plan') {
          if (this.item.user_split_overwritten && parseFloat(this.item.agent_pay_raw) === 0) {
            this.value = 'no-plan'
            this.$refs.selector.internalValue = 'no-plan'
          }

          return false
        }
      })
    },
    item: {
      deep: true,
      handler() {
        this.$debug.info('Commission plan selector item updated')
        this.initializeValue()
      },
    },
    commissionStatementTable() {
      this.$forceUpdate()
    },
  },
  computed: {
    options() {
      let output = []

      if (this.item.type === 'NBS' || this.item.type === 'RWL') {
        output.push({
          display: 'No Plan',
          rate: '0 %NBS, 0 %RWL',
          value: 'no-plan',
        })
        output.push({
          display: 'Default Plan',
          rate: this.commissionSetup.new_percentage + ' %NBS, ' + this.commissionSetup.renewal_percentage + ' %RWL',
          value: 'default',
        })
      }

      if (
        this.item.user_split_overwritten ||
        this.item.isNegative ||
        (this.item.type !== 'NBS' && this.item.type !== 'RWL')
      ) {
        output.push({
          display: 'Custom',
          rate: '',
          value: 'custom',
        })
      }

      return output
    },
    showError() {
      return (this.value === 'null' || this.value === '') && this.showApproveFieldOutlines
    },
    ...mapGetters({
      commissionStatementTable: 'commissions/producerStatement/getCommissionsTable',
      commissionSetup: 'commissions/getCommissionSetup',
    }),
  },
  mounted() {
    this.initializeValue()
  },
}
</script>

<style scoped>
::v-deep .v-list-item {
  height: 3.375rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
