<template>
  <div>
    <div class="d-flex flex-col mt-3">
      <div class="flex-grow-1 mr-2 text-right">
        <v-select
          :items="bonusTypes"
          label="Select Type"
          item-value="display"
          item-text="display"
          filled
          hide-details
          style="max-width:200px; display:inline-block;"
          size="medium"
          v-model="value.label"
          @change="validate"
          :disabled="!canEdit"
          v-if="value.label !== 'Other' && (!value.other || !value.other.length)"
        ></v-select>
        <v-text-field
          label="Enter Type"
          style="max-width:200px; display:inline-block;"
          size="medium"
          filled
          hide-details
          maxlength="30"
          v-model="value.other"
          @change="validate"
          v-else
          :disabled="!canEdit"
        ></v-text-field>
      </div>
      <div class="flex-shrink-1 mr-2">
        <v-text-field
          label="Percent"
          v-model.number="value.rate"
          style="width:100px;"
          @keypress="isNumber($event)"
          hide-details
          filled
          size="medium"
          :disabled="!canEdit"
          v-if="value.mode === 0"
          @input="calculateFromPercentage"
        ></v-text-field>
        <v-text-field
          label="Amount"
          v-model.number="value.value"
          style="width:100px;"
          size="medium"
          @keypress="isNumber($event)"
          hide-details
          filled
          :disabled="!canEdit"
          v-if="value.mode === 1"
          @input="calculateFromAmount"
        ></v-text-field>
      </div>
      <div class="flex-shrink-1">
        <v-btn-toggle v-model="value.mode" mandatory :disabled="!canEdit" @change="modeChanged">
          <v-btn text :disabled="!canEdit" class="bonusTypeToggleButton">
            %
          </v-btn>
          <v-btn text :disabled="!canEdit" class="bonusTypeToggleButton">
            $
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="flex-shrink-1">
        <v-tooltip bottom v-if="errors.length">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="red--text ml-3" v-bind="attrs" v-on="on">
              {{ icons.mdiAlertCircleOutline }}
            </v-icon>
          </template>
          <div v-if="errors.length">
            <div v-for="error in errors" class="my-2">{{ error }}</div>
          </div>
        </v-tooltip>
        <v-btn icon plain text v-if="canEdit" @click.prevent="remove(value)" class="mt-2 deleteBonusRowButton">
          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiAlertCircleOutline,
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiPercent,
} from '@mdi/js'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import * as yup from 'yup'

export default {
  props: ['value', 'commissionStatement', 'total'],
  data() {
    return {
      errors: [],
      bonusTypes: [
        //{
        // id: 'production_goal',
        //display: 'Production Goal',
        //},
        {
          id: 'agency_revenue',
          display: 'Agency Revenue',
        },
        /*{
          id: 'carrier_incentive',
          display: 'Carrier Incentive',
        },
        {
          id: 'holiday',
          display: 'Holiday',
        },*/
        {
          id: 'other',
          display: 'Other',
        },
      ],
      icons: {
        mdiCalendar,
        mdiAccount,
        mdiCurrencyUsd,
        mdiPercent,
        mdiClose,
        mdiCheck,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
      },
    }
  },
  methods: {
    remove() {
      this.$emit('removeBonus', this.value)
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-']
      const keyPressed = evt.key

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    validate: _.debounce(function() {
      let data = {
        label: this.value.label,
      }

      let schema = null

      if (this.value.mode === 0) {
        let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/

        data.rate = this.value.rate
        schema = yup.object().shape({
          rate: yup
            .number()
            .typeError('Percentage must be a number')
            .required()
            .max(100)
            .min(-100),

          label: yup.string().required(),
        })
      } else {
        data.value = this.value.value
        schema = yup.object().shape({
          value: yup
            .number()
            .typeError('Amount must be a number')
            .required(),
          label: yup.string().required(),
        })
      }

      this.errors = []

      schema.validate(data).catch(error => {
        this.errors = error.errors
      })

      if (this.value.label === 'Other' && (!this.value.other || !this.value.other.length)) {
        this.errors.push('Type is required')
      }

      if (schema.isValid(data) && this.errors.length === 0) {
        this.errors = []
        this.$emit('input', this.value)
      }
    }, 500),
    calculateFromPercentage() {
      this.value.rate = this.value.rate.toString().replace(/\s+/g, '')

      if (isNaN(this.value.rate) || this.value.rate === '' || this.value.rate === '-') {
        return
      }

      if (parseFloat(this.baseCommissionAmount) <= 0) {
        this.$debug.info('commissions_total is 0, skipping amount calculation')
        this.value.value = 0
      } else {
        this.$debug.info('calculating amount base on rate')

        this.value.rate = this.value.rate.toString().replace(/\s+/g, '')

        this.value.value = (this.baseCommissionAmount * (parseFloat(this.value.rate) / 100)).toFixed(2)
      }

      if (isNaN(this.value.value)) {
        this.value.value = 0
      }

      this.validate()
    },
    calculateFromAmount() {
      this.value.value = this.value.value.toString().replace(/\s+/g, '')

      if (isNaN(this.value.rate) || this.value.value === '' || this.value.value === '-') {
        return
      }

      console.log('value is', this.value.value)

      this.value.value = parseFloat(this.value.value.toString().replace(/\s+/g, ''))

      if (parseFloat(this.baseCommissionAmount) <= 0) {
        this.$debug.info('commissions_total is 0, skipping rate calculation')
        this.value.rate = 0
      } else {
        this.$debug.info('calculating rate based on amount')
        this.value.rate = this.value.rate.toString().replace(/\s+/g, '')
        this.value.rate = ((parseFloat(this.value.value) / this.baseCommissionAmount) * 100).toFixed(2)
      }

      if (isNaN(this.value.rate)) {
        this.value.rate = 0
      }

      this.validate()
    },
    modeChanged() {
      this.value.rate = 0
      this.value.value = 0
      this.$emit('input', this.value)
    },
  },
  mounted() {
    if (!('mode' in this.value)) {
      this.value.mode = 1
    }
  },
  computed: {
    ...mapGetters({
      isFinalized: 'commissions/monthlyReview/getFinalized',
      baseCommissionAmount: 'commissions/producerStatement/getBaseCommissionAmount',
    }),
    canEdit() {
      return this.isFinalized === false
    },
  },
  watch: {
    total() {
      if (this.value.mode === 0) {
        this.calculateFromPercentage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 55px !important;
}

.bonusTypeToggleButton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.deleteBonusRowButton {
  padding-right: 0px !important;
}
</style>
