<template>
  <percent-input-with-previous-value
    v-model="record.agent_pay_rate_raw"
    :previousValue="originalAmount"
    :disabled="!canEdit"
    v-if="showEditValueFields"
    @input="calculateRecordAmount(record)"
  />
</template>

<script>
import PercentInputWithPreviousValue from '@/pages/commissions/commission-statements/wizard/steps/producer/PercentInputWithPreviousValue'
import { mapGetters } from 'vuex'
import RecordAmountsMixin from '../RecordAmountsMixin'

export default {
  components: { PercentInputWithPreviousValue },
  props: ['item', 'showEditValueFields'],
  mixins: [RecordAmountsMixin],
  data() {
    return {
      record: {
        original: {},
      },
    }
  },
  methods: {
    refreshRecord() {
      this.record = this.$_.cloneDeep(this.item)
    },
    calculateRecordAmount(record) {
      let isValid = this.validateRecord(record, 'percent')

      this.$debug.info('is percent valid ' + isValid)
      if (isValid) {
        if (record.agent_pay_rate_raw === 0) {
          record.agent_pay_raw = 0
        } else {
          record.agent_pay_raw = record.agency_commissions_raw * (record.agent_pay_rate_raw / 100)
        }

        this.$debug.info('calculated record amount')
        this.updateRecordAmount(record)
      }
    },
  },
  mounted() {
    this.refreshRecord()
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.refreshRecord()
      },
    },
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
      isFinalized: 'commissions/monthlyReview/getFinalized',
    }),
    canEdit() {
      return this.isFinalized === false
    },
    originalAmount() {
      if (this.item.user_split_overwritten === false) {
        return null
      }

      if (this.commissionSetup.type === 'single') {
        if (this.item.type === 'NBS') {
          return this.commissionSetup.new_percentage
        }

        if (this.item.type === 'RWL') {
          return this.commissionSetup.renewal_percentage
        }
      }

      if (this.commissionSetup.type === 'multiple') {
        return this.item.original.percent
      }
    },
  },
}
</script>

<style scoped></style>
