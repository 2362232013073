var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localTableData,"loading":_vm.loading,"show-expand":"","hide-default-footer":"","disable-pagination":"","id":"ProducerCommissionsTable"},scopedSlots:_vm._u([{key:"item.effective_date",fn:function(ref){
var item = ref.item;
return [_c('commission-effective-column',{attrs:{"item":item}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('commission-type-column',{attrs:{"item":item}})]}},{key:"item.lob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lob)+" ")]}},{key:"item.insured",fn:function(ref){
var item = ref.item;
return [_c('commission-insured-column',{attrs:{"item":item}})]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_c('commission-carrier-column',{attrs:{"item":item}})]}},{key:"item.ivans_status",fn:function(ref){
var item = ref.item;
return [_c('commission-status-column',{attrs:{"item":item}})]}},{key:"item.agency_commissions",fn:function(ref){
var item = ref.item;
return [_c('commission-agency-commission-column',{attrs:{"item":item}})]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_c('commission-plan-column',{attrs:{"item":item,"showApproveFieldOutlines":_vm.showApproveFieldOutlines}})]}},{key:"item.agent_pay_rate",fn:function(ref){
var item = ref.item;
return [_c('commission-pay-rate-column',{attrs:{"item":item,"showEditValueFields":_vm.showEditValueFields(item)}})]}},{key:"item.agent_pay",fn:function(ref){
var item = ref.item;
return [_c('commission-agent-pay-column',{attrs:{"item":item,"showEditValueFields":_vm.showEditValueFields(item)}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"extendedRowContainer d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("POLICY NUMBER")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.policy))])]),_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("LOB")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.lob))])]),_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("PREMIUM")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.premium))])]),_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("TERM")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.terms)+" Months")])])])])]),_c('div',{staticClass:"flex-grow-1"},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("BASE AMOUNT")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.final_amount))])]),(_vm.hasAggregator)?_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("AGGREGATOR COMMISSION")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.aggregator_cut))])]):_vm._e(),(_vm.hasAggregator)?_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("AGENCY PAY")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.agency_commissions))])]):_vm._e()])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }