<template>
  <div class="carrier">
    {{ item.carrier}}
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style scoped>
.carrier{
  text-transform: capitalize;
}
</style>
