<template>
  <div>
    <v-select
      :value="value"
      :items="options"
      item-text="display"
      item-value="value"
      label="Select A Plan"
      :error="showError"
      @change="valueChanged"
      style="width: 150px"
      ref="selector"
      single-line
      dense
      hide-details
      v-if='options && options.length'
    >
      <template v-slot:item="{ item }">
        <div>
          <div>
            {{ item.display }}
          </div>
          <div v-if="item.rate.length" style="color: #7c7c7c">
            <small>{{ item.rate }}</small>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: [
    'item',
    'showApproveFieldOutlines'
  ],
  data() {
    return {
      value: '',
      showCustomLabel: false,
    }
  },
  methods: {
    ...mapActions({
      setCommission: 'commissions/producerStatement/setCommission',
      updateCommissionPlan: 'commissions/producerStatement/updateCommissionPlan',
    }),
    valueChanged(newValue) {
      this.value = newValue

      if (newValue === 'custom') {
        return
      }

      if (newValue === 'no-plan') {
        this.updateCommissionPlan({
          commissionId: this.item.id,
          planId: 'no-plan',
        }).then(() => {
          this.$debug.info('No plan option selected, setting values to 0')
        })

        return
      }

      this.$debug.info('Plan selected, updating')
      this.updateCommissionPlan({
        commissionId: this.item.id,
        planId: newValue,
      }).then(() => {
        this.$debug.info('Plan updated, sending fresh data to store')
      })
    },
  },
  watch: {
    options(options) {
      this.$_.forEach(options, option => {
        if (option.value === 'custom') {
          if (this.item.user_split_overwritten && this.item.agent_pay_raw !== 0) {
            this.value = 'custom'
            this.$refs.selector.internalValue = 'custom'
          }

          return false
        }
      })
    },
    item: {
      deep: true,
      handler() {
        this.$debug.info('Commission plan selector item updated')
      },
    },
    commissionStatementTable() {
      this.$forceUpdate()
    },
  },
  computed: {
    options() {
      let output = []

      if (this.item.type === 'NBS' || this.item.type === 'RWL') {
        output.push({
          display: 'No Plan',
          rate: '0 %NBS, 0 %RWL',
          value: 'no-plan',
        })
        this.$_.forEach(this.item.plans, plan => {
          output.push({
            display: plan.attributes.name, // + " ("+plan.attributes.new_percentage+" %NBS, "+plan.attributes.renewal_percentage+" %RWL)",
            rate: plan.attributes.new_percentage + ' %NBS, ' + plan.attributes.renewal_percentage + ' %RWL',
            value: plan.id,
          })
        })
      }

      if ((this.item.user_split_overwritten && parseFloat(this.item.agent_pay_raw) !== 0) || this.item.isNegative) {
        output.push({
          display: 'Custom',
          rate: '',
          value: 'custom',
        })
      }

      return output
    },
    showError() {
      return (this.value === 'null' || this.value === '') && this.showApproveFieldOutlines
    },
    ...mapGetters({
      commissionStatementTable: 'commissions/producerStatement/getCommissionsTable',
    }),
  },
  mounted() {
    if (this.item.plan) {
      this.value = this.item.plan.id
    }

    if (this.item.isNegative || (this.item.type !== 'NBS' && this.item.type !== 'RWL')) {
      this.value = 'custom'
    } else if (this.item.user_split_overwritten && parseFloat(this.item.agent_pay_raw) === 0) {
      this.value = 'no-plan'
    } else if (this.item.user_split_overwritten) {
      this.value = 'custom'
    }

    if (this.value) {
      this.$refs.selector.internalValue = this.value
    }
  },
}
</script>

<style scoped>
::v-deep .v-list-item {
  height: 3.375rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
