<template>
  <div>
    <reason-edit-select
      style="min-width:80px;"
      :commission="item"
      @change="updateCommissionReason"
      v-if="item.is_manual"
    />
    <span v-else>{{ item.type }}</span>
  </div>
</template>

<script>
import ReasonEditSelect from '../ReasonEditSelect'
import { mapActions } from 'vuex'
export default {
  components: { ReasonEditSelect },
  props: ['item'],
  methods: {
    updateCommissionReason({ commission, reasonCode }) {
      this.$api.commissions
        .updateReason({
          commission: commission.id,
          reason: reasonCode,
        })
        .then(({ data }) => {
          this.$debug.info('commission reason updated')
          this.setCommission(data);
          this.refreshCommissionStatement();
        })
    },
    ...mapActions({
      setCommission: 'commissions/producerStatement/setCommission',
      refreshCommissionStatement: 'commissions/producerStatement/refreshStatement',
    }),
  },
}
</script>

<style scoped></style>
