<template>
  <v-select
    hide-details
    :items="reasonCodes"
    v-model="commissionReasonCode"
    @change="reasonCodeChanged"
    placeholder="Select"
    :return-object="false"
    item-text="display"
    item-value="value"
    class="mt-1"
  >
    <template v-slot:item="{ item }">
      <div style="display:block;">
        <span style="text-transform: uppercase; font-weight:bold; font-family:Monospace;">({{ item.value }})</span>
        {{ item.description }}
      </div>
    </template>
  </v-select>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['commission'],
  data() {
    return {
      commissionReasonCode: null,
    }
  },
  methods: {
    reasonCodeChanged(reasonCode) {
      let event = {
        commission: this.commission,
        reasonCode: reasonCode,
      };

      this.$emit('change', event);
    }
  },
  mounted() {
    this.commissionReasonCode = this.commission.type;
  },
  watch: {
    commission(newValue, oldValue) {
      if (this.$_.isEqual(newValue, oldValue) === false) {
        this.commissionReasonCode = this.commission.type;
      }
    }
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'getCommissionSetup',
    }),
    reasonCodes() {
      let reasonCodes = this.commissionSetup.configuration.reason_codes;

      return this.$_.map(reasonCodes, (description, reasonCode) => {
        return {
          value: reasonCode,
          display: reasonCode,
          description: description,
        }
      });
    }
  },
}
</script>

<style scoped>

</style>
