<template>
  <div class="text-right">
    <v-text-field
      v-model="record.agency_commissions_raw"
      suffix="$"
      reverse
      dense
      hide-details
      @focus="hasFocus = true"
      @blur="hasFocus = false"
      @keyup="saveAgencyCommissions"
      v-if="record.is_manual"
    ></v-text-field>
    <span v-else>
      <span v-if="record.isNegative" class="red--text">{{ record.agency_commissions }}</span>
      <span v-else>{{ record.agency_commissions }}</span>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

export default {
  props: ['item'],
  data() {
    return {
      hasFocus: false,
      record: {},
    }
  },
  methods: {
    ...mapActions({
      setCommission: 'commissions/producerStatement/setCommission',
      refreshStatementTotals: 'commissions/producerStatement/refreshStatementTotals',
    }),
    refreshRecord() {
      this.record = this.$_.cloneDeep(this.item)
    },
    saveAgencyCommissions: _.debounce(function() {
      this.$debug.info('saving agency commissions')
      this.$debug.info(this.record)

      this.$api.commissions
        .updateAgencyAmount({
          commission: this.record.id,
          final_amount: this.record.agency_commissions_raw,
        })
        .then(({ data }) => {
          this.$debug.info('agency commissions saved')
          this.$debug.info(data)

          this.setCommission(data)
          this.refreshStatementTotals()
        })
    }, 700),
  },
  mounted() {
    this.refreshRecord()
  },
  watch: {
    item: {
      handler: function(newVal, oldVal) {
        if (this.hasFocus === false) {
          this.refreshRecord()
        }
      },
      deep: true,
    },
    hasFocus: {
      handler: function(newVal, oldVal) {
        if (newVal === false) {
          this.refreshRecord()
        }
      },
    },
  },
}
</script>

<style scoped></style>
