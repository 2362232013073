<template>
  <span style="text-transform: capitalize;">
    {{ planName }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['item'],
  computed: {
    planName() {
      if (this.commissionSetup.type === 'single') {
        if (this.item.user_split_overwritten && this.item.agent_pay_raw === 0) {
          return 'No Plan'
        } else if (this.item.user_split_overwritten) {
          return 'Custom'
        } else if (
          this.item.type === 'NBS' &&
          this.item.plan === null &&
          this.item.agent_pay_rate_raw === this.commissionSetup.new_percentage
        ) {
          return 'Default'
        } else if (
          this.item.type === 'RWL' &&
          this.item.plan === null &&
          this.item.agent_pay_rate_raw === this.commissionSetup.renewal_percentage
        ) {
          return 'Default'
        } else {
          return ''
        }
      } else {
        if (this.item.user_split_overwritten && this.item.agent_pay_raw === 0) {
          return 'No Plan'
        } else if (this.item.user_split_overwritten) {
          return 'Custom'
        } else if (this.item.plan) {
          return this.item.plan.attributes.name
        } else {
          return ''
        }
      }
    },
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
  },
}
</script>

<style scoped></style>
