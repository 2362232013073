<template>
  <div class="d-flex">
    <div v-if="previousValue && false" style="width:70px;position:relative;">
      <v-text-field
        v-model="itemPreviousValue"
        prefix="$"
        class="previousValue"
        readonly
        dense
        hide-details
      ></v-text-field>
      <div class="previousOverlay"></div>
    </div>
    <div style="width:70px;">
      <v-text-field
        v-model="itemValue"
        suffix="$"
        onClick="this.select()"
        @keypress="isNumber($event)"
        @keyup="sendValue"
        @focus="fieldInFocus = true"
        @blur="
          fieldInFocus = false
          refreshValue()
        "
        :disabled="disabled"
        dense
        reverse
        hide-details
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'previousValue', 'disabled'],
  data() {
    return {
      itemPreviousValue: null,
      itemValue: null,
      fieldInFocus: false,
    }
  },
  methods: {
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-']
      const keyPressed = evt.key

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    refreshValue() {
      if (this.fieldInFocus) {
        this.itemPreviousValue = this.$_.cloneDeep(this.previousValue)
        return
      }

      this.itemValue = this.$_.cloneDeep(this.value)
    },
    sendValue(event) {
      if (event.key === 'Tab' || event.key === 'Shift') {
        return
      }

      this.itemValue = this.itemValue.toString().replace(/\s+/g, '')

      if (this.itemValue === '-') {
        return
      }

      this.itemValue = parseFloat(this.itemValue)

      if (isNaN(this.itemValue) || this.itemValue === '') {
        this.itemValue = ''
        return
      }

      this.$emit('input', this.itemValue)
    },
  },
  mounted() {
    this.refreshValue()
  },
  watch: {
    previousValue: function(newValue) {
      this.refreshValue()
    },
    value: function(newValue) {
      this.refreshValue()
    },
  },
}
</script>

<style scoped>
::v-deep .v-text-field__prefix {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
::v-deep .v-text-field__suffix {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
::v-deep .previousValue .v-text-field__slot > input {
  text-decoration: line-through;
  color: #a9a9a9;
}

::v-deep .previousValue > * {
  color: #a9a9a9;
}

.previousOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
</style>
